import { labelGetter } from '../../helpers/labelGetter';
import { useListFilter } from '../../hooks/useListFilter';

export const namedItems = [1, 2, 3].map((i) => ({
  slug: `h${i}`,
  value: `H${i}`,
  label: `H${i}`,
}));

export const heightLabel = labelGetter(namedItems);

export const useHeight = useListFilter({
  namedItems,
  attribute: 'height',
  urlParam: 'hoogte',
  label: 'Hoogte',
});
