import { useEffect, useMemo } from 'react';
import { useMenu } from 'react-instantsearch-hooks-web';
import { routing } from '../algoliaRouting';
import { buildTitle } from '../../helpers/adCardContent';
import { unescape } from 'html-escaper';
import { useRefinedItems } from '../../hooks/useRefinedItems';

const attribute = 'model.id';

export const useModelMenu = ({ hsModels, defaultUpdater }) => {

  const refinedMakeIds = useRefinedItems("make.id").map(i => i.value);
  const { refine: refineMake } = useMenu({
    attribute: 'make.id',
    limit: 1,
  });

  const params = useMenu({ attribute, limit: 500 });
  const _items = useRefinedItems(attribute, params.items)

  const items = useMemo(
    () =>
      _items.reduce((a, i) => {
        const hsModel = hsModels[i.value];
        if (hsModel) {
          const name = unescape(buildTitle(
            hsModel.brand && `${hsModel.brand.content}`,
            `${hsModel.name.content}`
          ));
          a.push({
            ...i,
            label: `${name} (${i.count})`,
            slug: hsModel.slug,
            makeId: hsModel?.brand?.id,
            name,
            modelName: hsModel.name.content,
          });
        }
        return a;
      }, []),
    [hsModels, _items]
  );
  const current = items.find((i) => i.isRefined) || null;

  useEffect(() => {
    const handle = {
      stateToRoute: (state, route) => {
        const id = state.menu?.['model.id'];
        route.modelSlug =
          id && Object.values(hsModels).find((i) => i.id === id)?.slug;
      },
      routeToState: (route, state) => {
        state.menu = state.menu || {};
        const { brandSlug, modelSlug } = route;
        if (brandSlug && modelSlug) {
          state.menu['model.id'] = Object.values(hsModels).find(
            (i) => i.slug === modelSlug && i.brand?.slug === brandSlug
          )?.id;
        }
      },
    };
    routing.add(handle);
    return () => {
      routing.remove(handle);
    };
  }, [hsModels]);

  return {
    type: 'menu',
    title: 'Model',
    state: {
      ...params,
      refine: (v) => {
        if (v) {
          const makeId = items.find((i) => i.value === v)?.makeId;
          if (makeId && !refinedMakeIds.includes(makeId)) {
            refineMake(makeId);
          }
        }
        params.refine(v || null);
      },
      clear: () => params.refine(null),
      items,
      current,
      isRefined: !!current,
      activeFilterLabel: current?.modelName,
    },
  };
};
