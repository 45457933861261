import odometer from '../../helpers/odometer';
import { useRangeFilter } from '../../hooks/useRangeFilter';

const odometerStep = (i) => (i < 25000 ? 5000 : i < 200000 ? 25000 : 50000);
const odometerValues = (start, end) => {
  let i = 0,
    p = 0;
  while (i < start) {
    p = i;
    i += odometerStep(i);
  }
  const items = [p];
  if (i > p) items.push(i);
  while (i <= end) {
    i += odometerStep(i);
    items.push(i);
  }
  return items;
};

export const useOdometer = useRangeFilter({
  attribute: 'odometer',
  urlParam: 'km',
  label: 'Kilometerstand',
  getItems: (min, max) => {
    if (max === 0) return [];
    const items = odometerValues(min, max).map((i) => ({
      label: odometer(i, false),
      value: i,
    }));
    return items;
  },
});
