import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useMemo } from 'react';

const googleJsApiLoaderProps = {
  id: 'google-maps-geocoding',
  googleMapsApiKey: process.env.REACT_GOOGLE_GEOCODE_API_KEY,
  libraries: ['places', 'geocoding'],
};

/**
 * Custom hook to load Google Maps JavaScript API using the specified loader properties.
 * Logs an error message if the API fails to load.
 * @returns {Object} Object containing a boolean value indicating if the Google Maps API is loaded.
 */
const useGoogleMapsJsApi = () => {
  const { isLoaded, loadError } = useJsApiLoader(googleJsApiLoaderProps);
  useEffect(() => {
    if (loadError) {
      console.error('Google Maps API failed to load:', loadError);
    }
  }, [loadError]);
  return { isLoaded };
};

/**
 * Custom hook to access the Google Maps AutocompleteService object once the API is loaded.
 * @returns AutocompleteService object or null if API is not loaded
 */
export const useGoogleMapsAutocomplete = () => {
  const { isLoaded } = useGoogleMapsJsApi();
  return useMemo(() => {
    if (!isLoaded) return null;
    return new window.google.maps.places.AutocompleteService();
  }, [isLoaded]);
};

/**
 * Custom hook to access the Google Maps Geocoder object once the API is loaded.
 * @returns Geocoder object or null if API is not loaded
 */
export const useGoogleMapsGeocoder = () => {
  const { isLoaded } = useGoogleMapsJsApi();
  return useMemo(() => {
    if (!isLoaded) return null;
    return new window.google.maps.Geocoder();
  }, [isLoaded]);
};
