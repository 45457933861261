import fuelType from '../../helpers/fuelType';
import { labelGetter } from '../../helpers/labelGetter';
import { useListFilter } from '../../hooks/useListFilter';

export const namedItems = [
  'B',
  'C',
  'D',
  'E',
  'H',
  'HB',
  'HD',
  'L',
  '3',
  'O',
].map((i) => ({
  slug: i.toLowerCase(),
  value: i,
  label: fuelType(i),
}));

export const fuelTypeLabel = labelGetter(namedItems);

export const useFuelType = useListFilter({
  namedItems,
  attribute: 'fuel_type',
  urlParam: 'brandstof',
  label: 'Brandstof',
});
