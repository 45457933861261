import { useEffect, useState } from 'react';
import { useSearch } from '../searchProvider';
import { routing } from '../algoliaRouting';

export const useKeywords = ({ defaultUpdater }) => {
  const { query, refine } = useSearch();
  const [value, setValue] = useState('');

  useEffect(() => {
    const handle = {
      stateToRoute: (state, route) => {
        route.filters = route.filters || {};
        if (state.query) route.filters.q = state.query;
      },
      routeToState: (route, state) => {
        state.query = route.filters?.q || '';
      },
    };
    routing.add(handle);
    return () => {
      routing.remove(handle);
    };
  }, []);

  useEffect(() => {
    setValue(query || '');
  }, [query]);

  const title = 'Trefwoorden';
  return {
    type: 'text',
    title,
    state: {
      current: value,
      clear: () => {
        setValue('');
        refine('');
      },
      value,
      isRefined: !!value,
      refine: (v) => {
        setValue(v);
        refine(v);
      },
      activeFilterLabel: `${title}: '${query}'`,
    },
  };
};
