import { labelGetter } from '../../helpers/labelGetter';
import transmission from '../../helpers/transmission';
import { useListFilter } from '../../hooks/useListFilter';

export const namedItems = ['H', 'A', 'S', 'C'].map((i) => ({
  slug: i.toLowerCase(),
  value: i,
  label: transmission(i),
}));

export const transmissionLabel = labelGetter(namedItems);

export const useTransmission = useListFilter({
  namedItems,
  attribute: 'transmission',
  urlParam: 'transmissie',
  label: 'Transmissie',
});
