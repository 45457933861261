import { labelGetter } from '../../helpers/labelGetter';
import { useListFilter } from '../../hooks/useListFilter';

export const namedItems = [
  { value: 'demonstrator', label: 'Demo', slug: 'd' },
  { value: 'new', label: 'Nieuw', slug: 'n' },
  { value: 'occasion', label: 'Occasion', slug: 'o' },
];
export const carStateLabel = labelGetter(namedItems);
export const useCarState = useListFilter({
  namedItems,
  attribute: 'state',
  urlParam: 'staat',
  label: 'Voertuigsoort',
});
