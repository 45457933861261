import { useEffect, useMemo } from 'react';
import { useMenu } from 'react-instantsearch-hooks-web';
import { routing } from '../algoliaRouting';
import { unescape } from 'html-escaper';
import { useRefinedItems } from '../../hooks/useRefinedItems';

const attribute = 'make.id';

export const useMakeMenu = ({ hsBrands, defaultUpdater }) => {

  const refinedModelIds = useRefinedItems("model.id").map(i => i.value);
  const { refine: refineModel } = useMenu({
    attribute: 'model.id',
    limit: 1,
  });

  const params = useMenu({ attribute, limit: 500 });
  const _items = useRefinedItems(attribute, params.items)

  const items = useMemo(
    () =>
      _items.reduce((array, i) => {
        const hsBrand = hsBrands[i.value];
        if (hsBrand)
          array.push({
            ...i,
            label: unescape(`${hsBrand.content} (${i.count})`),
            name: unescape(hsBrand.content),
            slug: hsBrand.slug,
          });
        return array;
      }, []),
    [hsBrands, _items]
  );
  const current = items.find((i) => i.isRefined) || null;

  useEffect(() => {
    const handle = {
      stateToRoute: (state, route) => {
        const id = state.menu?.['make.id'];
        route.brandSlug =
          id && Object.values(hsBrands).find((i) => i.id === id)?.slug;
      },
      routeToState: (route, state) => {
        state.menu = state.menu || {};
        state.menu['make.id'] =
          route.brandSlug &&
          Object.values(hsBrands).find((i) => i.slug === route.brandSlug)?.id;
      },
    };
    routing.add(handle);
    return () => {
      routing.remove(handle);
    };
  }, [hsBrands]);

  return {
    type: 'menu',
    title: 'Merk',
    state: {
      ...params,
      refine: (v) => {
        refinedModelIds.length && refineModel(null);
        params.refine(v || null);
      },
      clear: () => {
        refinedModelIds.length && refineModel(null);
        params.refine(null);
      },
      items,
      current,
      isRefined: !!current,
      activeFilterLabel: current?.name,
    },
  };
};
