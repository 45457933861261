import { DateTime } from 'luxon';
import { useRangeFilter } from '../../hooks/useRangeFilter';

export const useRegistration = useRangeFilter({
  attribute: 'registration_date',
  urlParam: 'jaar',
  label: 'Bouwjaar',
  isPeriod: true,
  valueToUrl: (value) => {
    return DateTime.fromMillis(value * 1000).year;
  },
  urlToValue: (year, edge) => {
    if (edge === 'to')
      return (
        DateTime.fromObject({ year: Number(year) + 1 }).toMillis() / 1000 - 1
      );
    else return DateTime.fromObject({ year: Number(year) }).toMillis() / 1000;
  },
  getItems: (min, max) => {
    if (max === 0) return [];
    const start = DateTime.fromMillis(min * 1000).year;
    const end = DateTime.fromMillis(max * 1000).year;
    let year = start;
    const items = [];
    while (year <= end) {
      items.push({
        label: `${year}`,
        fromValue: DateTime.fromObject({ year }).toMillis() / 1000,
        toValue: DateTime.fromObject({ year: year + 1 }).toMillis() / 1000 - 1,
      });
      year++;
    }
    return items;
  },
});
