import { labelGetter } from '../../helpers/labelGetter';
import { useListFilter } from '../../hooks/useListFilter';

export const namedItems = [1, 2, 3, 4, 5].map((i) => ({
  slug: `l${i}`,
  value: `L${i}`,
  label: `L${i}`,
}));

export const lengthLabel = labelGetter(namedItems);

export const useLength = useListFilter({
  namedItems,
  attribute: 'length',
  urlParam: 'lengte',
  label: 'Lengte',
});
