import { useEffect, useMemo } from 'react';
import { useRefinementList } from 'react-instantsearch-hooks-web';
import { compare } from '../../helpers/compare';
import { routing } from '../algoliaRouting';
import { useRefinedItems } from '../../hooks/useRefinedItems';

const attribute = 'body_type';
const urlParam = 'carrosserie';
const separator = ',';
const label = 'Carrosserie';

export const useBodyType = ({ defaultUpdater }) => {
  const { refine, items: __items } = useRefinementList({ attribute });
  const _items = useRefinedItems(attribute, __items)

  const items = useMemo(
    () =>
      _items
        .map((i) => ({ ...i, slug: i.value }))
        .sort((a, b) => compare(a.label, b.label)),
    [_items]
  );

  useEffect(() => {
    const handle = {
      stateToRoute: (route, state) => {
        state.filters = state.filters || {};
        const values = route?.refinementList?.[attribute] || [];
        if (values.length) {
          state.filters[urlParam] = values.join(separator);
        }
      },
      routeToState: (state, route) => {
        const slugs = state.filters?.[urlParam] || '';
        route.refinementList = route.refinementList || {};
        route.refinementList[attribute] = slugs
          .split(separator)
          .filter((i) => i);
      },
    };
    routing.add(handle);
    return () => {
      routing.remove(handle);
    };
  }, []);

  const current = useMemo(() => items.filter((i) => i.isRefined), [items]);

  return {
    type: 'multi',
    title: 'Carrosserie',
    state: {
      refine,
      clear: () => current.map((i) => refine(i.value)),
      items,
      current,
      isRefined: current.length > 0,
      activeFilterLabel: current.length === 1 ? current[0].label : label,
    },
  };
};
