import { labelGetter } from '../../helpers/labelGetter';
import { useListFilter } from '../../hooks/useListFilter';

export const namedItems = [1, 2, 3, 4, 5, 6, 7, 8].map((i) => ({
    slug: `${i}`,
    value: `${i}`,
    label: i === 1 ? "1 stoel" : `${i} stoelen`,
}));

export const seatsLabel = labelGetter(namedItems);

export const useSeatCount = useListFilter({
    namedItems,
    attribute: 'seat_count',
    urlParam: 'stoelen',
    label: 'Stoelen',
});
