import { useEffect, useMemo } from "react";
import { useRange } from "react-instantsearch-hooks-web";
import { routing } from "../providers/algoliaRouting";

/**
 * A hook that creates a range filter on a given attribute
 * 
 * @param {*} 
 * @returns 
 */
export const useRangeFilter = ({
    attribute,
    urlParam,
    label,
    valueToUrl = v => v,
    urlToValue = v => v,
    isPeriod = false,
    getItems }) => () => {

        const { start: current, range, refine } = useRange({ attribute });


        // add the url handling
        useEffect(() => {
            const handle = {
                stateToRoute: (state, route) => {
                    route.filters = route.filters || {}
                    if (!state.range?.[attribute]) return
                    const [_min, _max] = state.range[attribute]?.split(":")
                    const min = _min ? valueToUrl(Number(_min)) : null
                    const max = _max ? valueToUrl(Number(_max)) : null
                    if (min && max) route.filters[urlParam] = `${min}-${max}`
                    else if (min) route.filters[urlParam] = `>${min}`
                    else if (max) route.filters[urlParam] = `<${max}`
                },
                routeToState: (route, state) => {
                    const mObj = route?.filters?.[urlParam]?.match(/^(?:([<>])(\d+)|(\d+)-(\d+))$/)
                    if (!mObj) return
                    state.range = state.range || {}
                    const [, cmp, val, min, max] = mObj;
                    if (cmp === ">") state.range[attribute] = `${urlToValue(Number(val), "from")}:`
                    else if (cmp === "<") state.range[attribute] = `:${urlToValue(Number(val), "to")}`
                    else state.range[attribute] = `${urlToValue(min, "from")}:${urlToValue(max, "to")}`
                }
            }
            routing.add(handle)
            return () => {
                routing.remove(handle)
            }
        }, [])

        // get the current set filters
        const [min, max] = current || [null, null];

        // get the range of possible values from Algolia
        let rangeMin = range?.min;
        let rangeMax = range?.max;

        // if rangeMin and rangeMax are equal, there's nothing to filter
        if (rangeMin === rangeMax) {
            // if min or max is set not infinite, that means we are currently filtered
            rangeMin = Number.isFinite(min) ? min : undefined
            rangeMax = Number.isFinite(max) ? max : undefined

            // if there's nothing to filter, but we have one of the values set, we still need to calculate a range
            if (rangeMin === undefined) rangeMin = rangeMax
            if (rangeMax === undefined) rangeMax = rangeMin
        }

        // get the range from the concrete component
        const items = useMemo(() => getItems(rangeMin, rangeMax), [rangeMin, rangeMax])

        // determine which items to show in the from and to lists
        const itemsFrom = useMemo(() => items
            .slice(0, isPeriod ? undefined : items.length - 1)
            .map(i => ({ ...i, value: i.fromValue !== undefined ? i.fromValue : i.value }))
            .filter(i => i.value < max)
            , [items, max])
        const itemsTo = useMemo(() => items
            .slice(isPeriod ? 0 : 1)
            .map(i => ({ ...i, value: i.toValue !== undefined ? i.toValue : i.value }))
            .filter(i => i.value > min)
            , [items, min])

        // determine the currently selected items
        const [minItem, minItemSet] = useMemo(() => {
            const item = itemsFrom.find(i => i.value === min)
            if (item) return [item, true]
            return [itemsFrom[0], false]
        }, [min, itemsFrom])
        const [maxItem, maxItemSet] = useMemo(() => {
            const item = itemsTo.find(i => i.value === max)
            if (item) return [item, true]
            return [itemsTo[itemsTo.length - 1], false]
        }, [max, itemsTo])


        return {
            type: "from-to",
            title: label,
            state: {
                current: { min: minItem, max: maxItem },
                clear: () => {
                    refine([undefined, undefined])
                },
                isRefined: minItemSet || maxItemSet,
                from: {
                    items: itemsFrom,
                    current: minItem,
                    refine: (v) => {
                        refine([v < range?.min ? undefined : v, isFinite(max) ? max : undefined])
                    },
                },
                to: {
                    items: itemsTo,
                    current: maxItem,
                    refine: (v) => {
                        refine([isFinite(min) ? min : undefined, v > range?.max ? undefined : v,])
                    },
                },
                activeFilterLabel: label
            }
        }

    }