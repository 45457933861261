import { useRangeFilter } from '../../hooks/useRangeFilter';

export const usePowerHp = useRangeFilter({
  attribute: 'power_hp',
  urlParam: 'pk',
  label: 'Motorvermogen (PK)',
  getItems: (min, max) => {
    if (!max === 0) return [];
    if (min < 0) min = 0;
    const start = 0;
    const end = max > 2000 ? 2000 : max;
    let i = start;
    const items = [];
    while (i <= end) {
      items.push({
        label: `${i} PK`,
        value: i,
      });
      i += 50;
    }
    if (max > 2000) {
      items.push({
        label: `> 2000 PK`,
        value: max,
      });
    } else {
      items.push({
        label: `${i} PK`,
        value: i,
      });
    }
    return items;
  },
});
