import { useRangeFilter } from '../../hooks/useRangeFilter';

export const useTrailerLoadUnbraked = useRangeFilter({
  attribute: 'trailer_load_unbraked',
  urlParam: 'ongeremd',
  label: 'Treklast ongeremd',
  getItems: (min, max) => {
    if (!max === 0) return [];
    const start = Math.floor(min / 200) * 200;
    const end = max;
    let i = start;
    const items = [];
    while (i <= end) {
      items.push({
        label: `${i} kg`,
        value: i,
      });
      i += 200;
    }
    items.push({
      label: `${i} kg`,
      value: i,
    });
    return items;
  },
});
