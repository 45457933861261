import { labelGetter } from '../../helpers/labelGetter';
import { useListFilter } from '../../hooks/useListFilter';

export const namedItems = [
  { value: 'beige', label: 'Beige', slug: 'beige' },
  { value: 'blauw', label: 'Blauw', slug: 'blauw' },
  { value: 'brons_goud', label: 'Brons/Goud', slug: 'brons_goud' },
  { value: 'bruin', label: 'Bruin', slug: 'bruin' },
  { value: 'geel', label: 'Geel', slug: 'geel' },
  { value: 'grijs', label: 'Grijs', slug: 'grijs' },
  {
    value: 'groen_turquoise',
    label: 'Groen/Turquoise',
    slug: 'groen_turquoise',
  },
  { value: 'oranje', label: 'Oranje', slug: 'oranje' },
  { value: 'paars_lila', label: 'Paars/Lila', slug: 'paars_lila' },
  { value: 'rood', label: 'Rood', slug: 'rood' },
  { value: 'roze', label: 'Roze', slug: 'roze' },
  { value: 'wit', label: 'Wit', slug: 'wit' },
  { value: 'zilver', label: 'Zilver', slug: 'zilver' },
  { value: 'zwart', label: 'Zwart', slug: 'zwart' },
];

export const colorLabel = labelGetter(namedItems);

export const useColor = useListFilter({
  namedItems,
  attribute: 'short_color',
  urlParam: 'kleur',
  label: 'Kleur',
});
