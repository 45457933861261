import React, { useContext, useEffect, useMemo } from 'react';
import { usePagination, useSortBy } from 'react-instantsearch-hooks-web';
import { useMakeMenu } from './algoliaFilters/make';
import { useModelMenu } from './algoliaFilters/model';
import { useKeywords } from './algoliaFilters/keywords';
import { useDealerID } from './algoliaFilters/dealerId';
import { useBudget } from './algoliaFilters/budget';
import { useRegistration } from './algoliaFilters/registration';
import { useOdometer } from './algoliaFilters/odometer';
import { useCarType } from './algoliaFilters/carType';
import { useColor } from './algoliaFilters/color';
import { useCarState } from './algoliaFilters/carState';
import { useHeight } from './algoliaFilters/height';
import { useLength } from './algoliaFilters/length';
import { useTransmission } from './algoliaFilters/transmission';
import { useFuelType } from './algoliaFilters/fuelType';
import { useBodyType } from './algoliaFilters/bodyType';
import { useTrailerLoadBraked } from './algoliaFilters/trailerLoadBraked';
import { useTrailerLoadUnbraked } from './algoliaFilters/trailerLoadUnbraked';
import { routing } from './algoliaRouting';
import { usePowerHp } from './algoliaFilters/powerHp';
import { useMarginVat } from './algoliaFilters/marginVat';
import { useSeatCount } from './algoliaFilters/seatCount';
import { useDistance } from './algoliaFilters/location';

/**
 * Updates an object's filters property based on the provided key and value.
 * If the value is null, it deletes the key from filters; otherwise, it sets the key to the given value.
 * @param {Object} i - The object to be updated.
 * @param {string} k - The key in the filters object to be updated.
 * @param {any} v - The value to set for the key, or null to delete the key.
 * @returns {Object} - A new object with the updated filters property.
 */
const defaultUpdater = (i, k, v) => {
  if (!i.filters) i.filters = {};
  if (v === null) {
    delete i.filters[k];
  } else {
    i.filters[k] = v;
  }
  return { ...i };
};

/**
 * Array of sorting options for vehicles.
 * Each object represents a sorting criterion.
 * Properties: value, slug, label, main (optional).
 */
export const sortOptions = [
  {
    value: 'vehicles',
    slug: null,
    label: 'Relevantie',
    main: true,
  },
  {
    value: 'vehicles_price_low',
    slug: 'prijs-op',
    label: 'Maandbedrag (oplopend)',
    main: true,
  },
  {
    value: 'vehicles_price_high',
    slug: 'prijs-af',
    label: 'Maandbedrag (aflopend)',
  },
  {
    value: 'vehicles_production_low',
    slug: 'leeftijd-af',
    label: 'Bouwjaar (oud → nieuw)',
    main: true,
  },
  {
    value: 'vehicles_production_high',
    slug: 'leeftijd-op',
    label: 'Bouwjaar (nieuw → oud)',
    main: true,
  },
  {
    value: 'vehicles_mileage_low',
    slug: 'km-op',
    label: 'KM stand (oplopend)',
  },
];

const AlgoliaFilterContext = React.createContext({});

/**
 * AlgoliaFilterProvider is a React component that provides filtering and sorting functionalities
 * for an Algolia search interface. It utilizes various hooks to manage different filter states
 * and integrates with routing to synchronize state with the URL.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to be rendered within the provider.
 * @param {Object} props.filterData - The data used to initialize filters, including brands and models.
 *
 * @returns {JSX.Element} A provider component that supplies the filtering and sorting state to its children.
 *
 * @example
 * <AlgoliaFilterProvider filterData={filterData}>
 *   <YourComponent />
 * </AlgoliaFilterProvider>
 *
 * @description
 * This component sets up various filters such as make, model, distance, budget, and more using custom hooks.
 * It also manages pagination and sorting states. The filters are organized into sections and can be cleared
 * individually or collectively. The component ensures that the application state is kept in sync with the URL
 * through the use of routing handlers.
 */
export const AlgoliaFilterProvider = ({ children, filterData }) => {
  const hsBrands = useMemo(
    () =>
      Object.fromEntries(
        filterData?.brands?.items?.map((i) => [i.id, i]) || []
      ),
    [JSON.stringify(filterData?.brands)]
  );

  const hsModels = useMemo(
    () =>
      Object.fromEntries(
        filterData?.models?.items?.map((i) => [i.id, i]) || []
      ),
    [JSON.stringify(filterData?.models)]
  );

  const filters = {
    makeMenu: useMakeMenu({ hsBrands, defaultUpdater }),
    modelMenu: useModelMenu({ hsModels, defaultUpdater }),
    keywords: useKeywords({ defaultUpdater }),
    distance: useDistance({ defaultUpdater }),
    budget: useBudget({ defaultUpdater }),
    registration: useRegistration({ defaultUpdater }),
    odometer: useOdometer({ defaultUpdater }),
    carType: useCarType({ defaultUpdater }),
    fuelType: useFuelType({ defaultUpdater }),
    transmission: useTransmission({ defaultUpdater }),
    bodyType: useBodyType({ defaultUpdater }),
    length: useLength({ defaultUpdater }),
    height: useHeight({ defaultUpdater }),
    carState: useCarState({ defaultUpdater }),
    trailerLoadBraked: useTrailerLoadBraked({ defaultUpdater }),
    trailerLoadUnbraked: useTrailerLoadUnbraked({ defaultUpdater }),
    color: useColor({ defaultUpdater }),
    seatCount: useSeatCount({ defaultUpdater }),
    powerHp: usePowerHp({ defaultUpdater }),
    marginVat: useMarginVat({ defaultUpdater }),
    dealerId: useDealerID({ defaultUpdater }),
  };

  const pagination = usePagination();
  const sorting = useSortBy({
    items: sortOptions,
  });

  useEffect(() => {
    const handle = {
      stateToRoute: (state, route) => {
        route.page = state?.page;
      },
      routeToState: (route, state) => {
        state.page = route?.page;
      },
    };
    routing.add(handle);
    return () => {
      routing.remove(handle);
    };
  }, []);

  useEffect(() => {
    const handle = {
      stateToRoute: (state, route) => {
        route.filters = route.filters || {};
        if (state.sortBy)
          route.filters.s =
            state.sortBy &&
            sortOptions.find((s) => s.value === state.sortBy)?.slug;
      },
      routeToState: (route, state) => {
        const slug = route?.filters?.s;
        state.sortBy = slug && sortOptions.find((s) => s.slug === slug)?.value;
      },
    };
    routing.add(handle);
    return () => {
      routing.remove(handle);
    };
  }, []);

  useEffect(() => {
    routing.router.updated();
  }, []);

  const activeFilterKeys = Object.entries(filters)
    .filter(([, { state }]) => state.isRefined)
    .map(([k]) => k);

  const activeFilters = activeFilterKeys.map((key) => ({
    key,
    filter: filters[key],
  }));

  const state = {
    clear: () => activeFilters.reduce((cleared, { key, filter }) => {
      cleared.push(key)
        if (key === 'modelMenu' && cleared.includes('makeMenu')) {
          // clearing make already clears model
        return cleared
        }
      filter.state?.clear?.()
      return cleared
      }, []),
    hsBrands,
    hsModels,
    filterData,
    filters,
    activeFilters,
    sections: [
      // filters.dealerId,
      filters.makeMenu,
      filters.modelMenu,
      filters.keywords,
      filters.budget,
      filters.registration,
      filters.odometer,
      filters.powerHp,
      filters.distance,
      filters.carType,
      filters.marginVat,
      filters.fuelType,
      filters.transmission,
      filters.bodyType,
      filters.length,
      filters.height,
      filters.carState,
      {
        type: 'section',
        title: 'Treklast',
        items: [filters.trailerLoadBraked, filters.trailerLoadUnbraked],
      },
      filters.color,
      filters.seatCount,
    ],
    sorting,
    currentPage: pagination.currentRefinement + 1,
    setPage: (page) => pagination.refine(page - 1),
    pages: pagination.nbPages,
  };

  return (
    <AlgoliaFilterContext.Provider value={state}>
      {children}
    </AlgoliaFilterContext.Provider>
  );
};

/**
 *
 * @returns {Filter}
 */
export const useAlgoliaFilter = () => {
  return useContext(AlgoliaFilterContext);
};
