import { useListFilter } from "../../hooks/useListFilter"

export const namedItems = [
    { value: 'VAT', label: "BTW", slug: "nee" },
    { value: 'Margin', label: "Marge", slug: "ja" },
]

export const useMarginVat = useListFilter({
    namedItems,
    attribute: "vat_or_margin",
    urlParam: "marge",
    label: "BTW/Marge",
})