import { useEffect, useMemo, useState } from 'react';
import { useGeoSearch, useSearch } from '../searchProvider';
import { routing } from '../algoliaRouting';
import { useGoogleMapsGeocoder } from '../../helpers/geocoding';
import { createSharedResource } from '../../helpers/sharedResource';

const distanceOptions = [
  { filterLabel: '', label: 'Geen limiet', value: 0 },
  { filterLabel: 'Afstand tot 25 km', label: 'Max. 25 km', value: 25000 },
  { filterLabel: 'Afstand tot 50 km', label: 'Max. 50 km', value: 50000 },
  { filterLabel: 'Afstand tot 100 km', label: 'Max. 100 km', value: 100000 },
  { filterLabel: 'Afstand tot 150 km', label: 'Max. 150 km', value: 150000 },
  { filterLabel: 'Afstand tot 200 km', label: 'Max. 200 km', value: 200000 },
];

const gecodedPlace = createSharedResource(
  'gecodedPlace',
  () => Promise.resolve(null),
  null,
  {
    validFor: 1000 * 60 * 60 * 24, // 1 day
  }
);

/**
 * Custom hook to manage geographical distance-based search functionality.
 *
 * @param {Object} options - Configuration options for the hook.
 * @param {Function} options.defaultUpdater - Function to update the search state.
 *
 * @returns {Object} An object containing the location type, title, and state management functions.
 * @property {string} type - The type of the component, set to 'location'.
 * @property {string} title - The title of the component, set to 'Locatie'.
 * @property {Object} state - The state object containing distance options, current value, and state management functions.
 * @property {Array} state.distanceOptions - Options for distance filtering.
 * @property {Object} state.current - The current state of the distance filter.
 * @property {Function} state.clear - Function to clear the current filter state.
 * @property {Object} state.value - The current value of the distance filter.
 * @property {boolean} state.isRefined - Indicates if the filter is currently refined.
 * @property {Function} state.refine - Function to refine the filter with new place and distance.
 * @property {string} state.activeFilterLabel - Label for the active distance filter.
 *
 * @description
 * This hook integrates with the GeoSearch context to manage and synchronize the state of a distance-based search filter.
 * It uses the Google Maps Geocoder to resolve place IDs to geographical coordinates and updates the search state accordingly.
 * The hook also manages the synchronization between route state and search state, ensuring that the filter state is consistent
 * with the current route.
 */
export const useDistance = ({ defaultUpdater }) => {
  const { setLat, setLng, distanceInM, setDistanceInM } = useGeoSearch();
  const [value, setValue] = useState(null);
  const valuePlaceId = useMemo(
    () => value?.place?.place_id,
    [value?.place?.place_id]
  );
  const valueDistanceInM = useMemo(
    () => Number(value?.distanceInM),
    [value?.distanceInM]
  );

  useEffect(() => {
    const handle = {
      stateToRoute: (state, route) => {
        route.filters = route.filters || {};
        if (
          valuePlaceId &&
          valueDistanceInM &&
          state?.configure?.aroundLatLng
        ) {
          const [lat, lng] = state?.configure?.aroundLatLng.split(/, ?/) ?? [];
          route.filters.locatie_id = valuePlaceId;
          route.filters.gps = `${lat},${lng}`;
          route.filters.afstand = valueDistanceInM / 1000 + 'km';
        }
      },
      routeToState: (route, state) => {
        route.filters = route.filters || {};
        const placeId = route.filters.locatie_id;
        const distance = Number(route.filters.afstand?.replace(/km$/, '000'));
        if (placeId && Number.isFinite(distance)) {
          setValue((o) => {
            const newO = { ...o };
            if (newO.place?.place_id != placeId)
              newO.place = { place_id: placeId };
            if (newO.distanceInM != distance) newO.distanceInM = distance;
            return newO;
          });
        }
        const [lat, lng] = route.filters.gps?.split(/, ?/)?.map(Number) ?? [];
        if (lat && lng && Number.isFinite(distance)) {
          setLat(lat);
          setLng(lng);
          setDistanceInM(distance);
        }
      },
    };
    routing.add(handle);
    return () => {
      routing.remove(handle);
    };
  }, [valuePlaceId, valueDistanceInM]);

  useEffect(() => {
    setValue((o) => ({ ...o, distanceInM }));
  }, [distanceInM]);

  const geocoder = useGoogleMapsGeocoder();
  useEffect(() => {
    const fetchGeoData = async () => {
      if (!valuePlaceId) {
        setLat(null);
        setLng(null);
        return;
      }
      const cached = await gecodedPlace(valuePlaceId);
      let result = null;
      if (cached) result = cached;
      if (!result) {
        if (!geocoder) return;
        let coded = null;
        try {
          coded = await geocoder.geocode({ placeId: valuePlaceId });
        } catch (e) {
          // pass
        }
        if (!coded) {
          return;
        }
        result = coded.results[0];
        gecodedPlace.put(valuePlaceId, result);
      }
      const address = result?.formatted_address;
      const location = result?.geometry?.location;
      if (!location) {
        setLat(null);
        setLng(null);
        return;
      }

      setLat(
        typeof location?.lat === 'function' ? location?.lat() : location?.lat
      );
      setLng(
        typeof location?.lng === 'function' ? location?.lng() : location?.lng
      );

      // on load we may not have the label for the item, so add it now
      setValue((o) => {
        if (o.place && !o.place.description) {
          return {
            ...o,
            place: { ...o.place, description: address ?? 'Onbekend' },
          };
        }
        return o;
      });
    };

    fetchGeoData();
  }, [valuePlaceId, geocoder]);

  useEffect(() => {
    if (Number.isFinite(valueDistanceInM)) setDistanceInM(valueDistanceInM);
  }, [valueDistanceInM]);

  const distanceFilterLabel = useMemo(() => {
    return distanceOptions.find((o) => o.value === valueDistanceInM)
      ?.filterLabel;
  }, [valueDistanceInM]);

  const title = 'Locatie';
  return {
    type: 'location',
    title,
    state: {
      distanceOptions,
      current: value,
      clear: () => {
        setValue(null);
        setLat(null);
        setLng(null);
        setDistanceInM(null);
      },
      value,
      isRefined: valuePlaceId && valueDistanceInM,
      refine: ({ place: newPlace, distanceInM: newDistanceInM }) => {
        setValue({
          place: newPlace !== undefined ? newPlace : value?.place,
          distanceInM:
            newDistanceInM !== undefined ? newDistanceInM : value?.distanceInM,
        });
      },
      activeFilterLabel: distanceFilterLabel,
    },
  };
};
