import styled from '@emotion/styled';
import { mix } from 'polished';
import isPropValid from '@emotion/is-prop-valid';

export default styled('ol', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'color',
})`
  ${({ theme }) => theme.typography.controlStrong};
  padding: 0;
  margin: 3px 0;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 10px 0;
  color: ${({ theme, color }) => theme.colors[color]};
  li {
    display: inline-block;
    white-space: nowrap;
    &:not(:first-of-type):before {
      content: '\u2002\u2022\u2002';
    }
  }
  a {
    ${({ theme }) => theme.typography.underline};

    transition: color 150ms linear;
    ${({ theme }) => theme.media.hover} {
      &:hover {
        color: ${({ theme, color }) =>
          mix(0.5)('#FFFFFF')(theme.colors[color])};
      }
    }
    &:active {
      color: ${({ theme, color }) => mix(0.4)('#FFFFFF')(theme.colors[color])};
    }
  }
`;
