import { useRangeFilter } from '../../hooks/useRangeFilter';

export const useBudget = useRangeFilter({
  attribute: 'default_monthly_payment',
  urlParam: 'budget',
  label: 'Maandtarief',
  getItems: (min, max) => {
    if (!max) return [];
    if (min < 0) min = 0;
    let start = Math.pow(10, Math.floor(Math.log10(min)));
    if (start < 100) start = 0;

    const end = max;
    let i = start;
    const items = [];
    while (i <= end) {
      items.push({
        label: `€ ${i}`,
        value: i,
      });
      i +=
        i < 1000 ? 100 :
          i < 1500 ? 250 :
            i < 2500 ? 500 :
              i < 10000 ? 2500 :
                i < 50000 ? 5000
                  : 10000;
    }
    items.push({
      label: `€ ${i}`,
      value: i,
    });
    return items;
  },
});
